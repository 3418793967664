.button__bar {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    padding: 0;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 40px;
    width: 70px;
    text-align: center;
  }
  
  .button__bar li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    margin: 0 3px;
    padding: 0;
  }
  
  .button__bar li button {
    border: none;
    content: ".";
    background: #a53692;
    color: transparent;
    cursor: pointer;
    display: block;
    height: 5px;
    width: 5px;
    border-radius: 7.5px;
  }
  
  .button__bar li.slick-active button {
    background: rgba(155, 247, 34, 0.8) !important;
    content: ".";
  }
  
  .button__bar li button:hover,
  .button__bar li button:focus {
    background: rgba(155, 247, 34, 0.8);
    outline: 0;
  }
  
  .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
  }
  