.button__bar {
    display: inline-block;
    vertical-align: middle;
    margin: auto;
    padding: 0;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 40px;
    width: 70px;
    text-align: center;
  }
  
  .button__bar li {
    list-style: none;
    cursor: pointer;
    display: inline-block;
    
    margin: 0 3px;
    /* width: 1px; */
    padding: 0;
  }
  
  .button__bar li button {
    border: none;
    background: #cccbcc8a;
    color: transparent;
    cursor: pointer;
    display: block;
    /* content: "."; */
    font-size: 0;
    padding: 0;
    height: 7px;
    width: 7px;
    border-radius: 5px;
  }
  
  .button__bar li.slick-active button {
    background: #0163e7;
    border-radius: 7.5px;
    height: 5px;
    width: 17px;
  }
  
  .button__bar li button:hover,
  .button__bar li button:focus {
    background: #0163e7;
    outline: 0;
  }
  
  .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
  }
  