#root {
  /* background-color: white; */
  background-color: #f2f4f8;
}

.overlay {
  background: white;
  border: 1px solid #ccc;
  padding: 5px;
  max-width: 40em;
}

/* TO REMOVE AUTOFILL BACKGROUND COLOR */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  height: 6px !important;
  width: 6px !important;
} /* this targets the default scrollbar (compulsory) */

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.23) !important;
  border-radius: 24px;
  transition: all 0.5s;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(146, 151, 165, 1) !important;
}
/* this will style the thumb, ignoring the track */

::-webkit-scrollbar-button {
  background-color: transparent !important;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
  background-color: transparent !important;
} /* if both the vertical and the horizontal bars appear, then perhaps the right bottom corner also needs to be styled */

body {
  scrollbar-face-color: #f1f1f1 !important;
  margin: 0;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  background-color: #f0f4f7 !important;
}

.MuiPickersDateRangePickerInput-root {
  width: 100%;
}
/* use this class to attach this font to any element i.e. <p class="fontsforweb_fontid_9785">Text with this font applied</p> */
/* body {
	font-family: 'tanyacare_semi_bold_calibri', sans-serif !important;
}
code {
  font-family: 'tanyacare_semi_bold_calibri', sans-serif !important;
}
textarea:focus, input:focus{
  font-family: 'tanyacare_semi_bold_calibri', sans-serif !important;
}
input, select, textarea{
  font-family: 'tanyacare_semi_bold_calibri', sans-serif !important;
} */
/* * {
  font-family: 'tanyacare_semi_bold_calibri' !important;
}
html {
	font-family: 'tanyacare_semi_bold_calibri' !important;
} */

/* Font downloaded from FontsForWeb.com */

/* same font family name - you can differentiate using font weight or font style*/
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./font/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: url("./font/Roboto-Italic.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./font/Roboto-Medium.ttf");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("./font/Roboto-MediumItalic.ttf");
}

/* @font-face {
  font-family: "Roboto";
  src: url("./font/Roboto-Regular.ttf"), url("./font/Roboto-Italic.ttf"),
    url("./font/Roboto-Medium.ttf"), url("./font/Roboto-MediumItalic.ttf");
} */

/* .robotoRegular {
  font-family: "Roboto-Regular" !important;
}

.robotoRegularItalic {
  font-family: "Roboto-Italic" !important;
}
.robotoMedium {
  font-family: "Roboto-Medium" !important;
}

.robotoMediumItalic {
  font-family: "Roboto-Medium-Italic" !important;
} */

.css-2b097c-container {
  width: 100%;
}
:fullscreen {
  z-index: 5;
}

.pac-container {
  z-index: 100000;
}
.MuiPickersPopper-root {
  z-index: 1700 !important;
}

.MuiPickersClockNumber-clockNumberSelected .MuiTypography-body1 {
  color: white !important;
}

.css-g1d714-ValueContainer {
  overflow: hidden;
  max-height: 30px;
}

.MuiPickersCalendar-root {
  min-height: 230px !important;
}

.MuiPickersDesktopDateRangeCalendar-calendar {
  min-height: 255px !important;
}

/* Fix for react-pdf page canvas exceeding container width */
.react-pdf__Page__canvas {
  margin: 0 auto;
  width: 100% !important;
  height: 100% !important;
}

/* Fix for react-pdf page canvas scroll issue */
.react-pdf__Page__textContent {
  display: none;
}

/* Fix for MUI IOS Time Picker focus issue */
div[role="listbox"] {
  outline: none;
}