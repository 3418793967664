input,
.StripeElement {
  /* display: block; */
  margin: 10px 0 20px 0;
  /* max-width: 500px; */
  margin: 1rem auto;
  padding: 8.5px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, */
    /* rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
  border: 1px solid rgba(219, 222, 229, 0.49);
  outline: 0;
  border-radius: 4px;
  background: white;
}

input::placeholder {
  color: #aab7c4;
}

input:focus,
.StripeElement--focus {
  /* box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, */
    /* rgba(0, 0, 0, 0.0784314) 0px 1px 3px; */
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  border:1px solid rgba(219, 222, 229, 0.49);
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}